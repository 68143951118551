import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../componentes/forms/inputs/RTextField';
import RSelect from '../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------

export default function BusqForm({ showLoader, actualizaCampo, agrupacion, certificados }) {
  React.useEffect(() => {
    showLoader(false);
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1}>
        <RTextField type="number" name="idWeb" label="Código*" actualizaCampo={actualizaCampo} value={agrupacion.idWeb} md={2} />
        <RSelect name="idCertificado" label="Certificado*" actualizaCampo={actualizaCampo} value={agrupacion.idCertificado} md={5}
          values={certificados}
        />
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={agrupacion.nombre} md={3} />
        <RTextField type="number" name="anyo" label="Año*" actualizaCampo={actualizaCampo} value={agrupacion.anyo} md={2} />
      </Grid>
    </form>
  );
}
