import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import {buildBody, isEmpty, workWithLocalStorage, getUniqueKey} from '../../auxiliares/funciones';
import FicForm from './form/ficform';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    minWidth: '650px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    maxHeight: '650px',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [incidencias, setIncidencias] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());

    const campos = ['id','nombre','total','descargasHechas','descargasIncidencias','empresasHechas','empresasIncidencias','fechaAlta','activo'];
    const cabecera = ['ID','Nombre','Total Lineas','Grupos Hechas','Grupos Inc.','Emp. Hechas','Emp. Inc','Fecha Alta','Estado'];
    const camposI = ['tipo','nombre','agrupacion','referencia','incidencia'];
    const cabeceraI = ['Tipo','Nombre','Agrupación','Referencia','Incidencia'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
      if(!cargado){
        const body = {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}descargas`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);            
            setCargado(true);
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true); 
      // setCargado(false);
      setCargado(false)
    }

    const validaBorrar = (obj) => obj.activo === 'Activo';

    const Borrar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
            id: obj.id,
            activo: 0,
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}master/descarga`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Fichero desactivado!';
              if (result.error === 0) {    
                setLista([]);               
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const Relanzar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {
            id: obj.id,
            activo: 0,
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}descarga/relanzar`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Fichero relanzado!';
              if (result.error === 0) {
                setLista([]);
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const muestraIncidencias = (obj) => {
        props.showLoader(true);
        const bodyJson = JSON.stringify(buildBody(usuario.token, { id: obj.id}))
        fetch(`${CONFIG.api}descarga/incidencias`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setIncidencias(result.data);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);
            setModalOpenI(true)
        })
    }

    const theme = useTheme();
    const acciones = [
      {
        color:'primary',
        nombre:'Incidencias',
        icono:<i />,
        funcion: muestraIncidencias,
        estilo: {},
        condition: null
      },
      {
        color:'warning',
        nombre:'Relanzar',
        icono:<i />,
        funcion: Relanzar,
        estilo: {},
        condition: validaBorrar
      },
      {
        color:'error',
        nombre:'Borrar',
        icono:<i />,
        funcion: Borrar,
        estilo: {},
        condition: validaBorrar
      }
    ]

    const [modalOpen, setModalOpen] = React.useState(false);
    const cierraModal = () => {
        setModalOpen(false);
        setCargado(false);
    }
    const abreModal = () => setModalOpen(true);

    const [modalOpenI, setModalOpenI] = React.useState(false);
    const cierraModalI = () => {
        setModalOpenI(false);
    }


  return (    
    <Page title="Descargas PDFs" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                                <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                                    onClick={()=>abreModal()}>Subir Fichero
                                </Button>
                              </>}
                  title="Descargas PDFs"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
        <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={style}>
                <FicForm cierraModal={cierraModal}/>
            </Card>
        </Modal>
        <Modal open={modalOpenI} onClose={cierraModalI} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={style2}>
                <CardHeader title={'Detalle Incidencias'}
                action={<CloseIcon fontSize="inherit" onClick={() => cierraModalI() }/>}/>
                <Divider style={{ margin: '15px'}} />
                <CardContent sx={{paddingButtom : 0}}>
                    <RTabla lista={incidencias} campos={camposI} cabecera={cabeceraI} acciones={[]}/>
                </CardContent>
            </Card>
        </Modal>
    </Page>
  );
}
