import React from 'react';
// @mui
import {Grid, Button, CardHeader, Divider, CardContent, Select, MenuItem} from '@mui/material';
import FileUpload from "react-mui-fileuploader";
import Swal from 'sweetalert2';
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";

import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, showLoader } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';
import Iconify from "../../../componentes/Iconify";

// ----------------------------------------------------------------------

export default function EstadosForm({ cierraModal, certificados }) {

  const [fileKey, setFileKey] = React.useState(getUniqueKey());
  const [filesForm, setFiles] = React.useState([]);
  const [cert, setCert] = React.useState(0);
  const usuario = workWithLocalStorage('get','usuario',null);

  const handleFilesChange = (files) => {
    // Update chosen files
    setFiles([...files]);
  };

  const handleChange = (event) => {
        setCert(event.target.value);
        console.log(event.target.value);
    }

  const uploadFiles = () =>{
    // Create a form and post it to server
    const formData = new FormData();
    filesForm.forEach((file) => formData.append("files", file,file.name));
    if(cert === 0){
        Swal.fire({
                title: 'Debes seleccionar un certificado',
                buttons: [{label: 'Ok'}]
            })
    }else if (!isEmpty(filesForm) && filesForm.length === 1) {
        // eslint-disable-next-line react/prop-types
        showLoader(true);
        const body = {};
        body.idCertificado = cert
        const bodyJson = JSON.stringify(buildBody(usuario.token, body))
        formData.append("str", bodyJson);
        fetch(`${CONFIG.api}subir`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then((result) => {
                let msg = 'Documento subido!';
                if (result.error === 0) {
                    cierraModal()
                } else {
                    msg = 'Ha ocurrido un error. Hablad con Rubén';
                }
                showLoader(false);
                Swal.fire({
                    title: msg,
                    buttons: [{label: 'Ok'}]
                })
            })
    } else {
        Swal.fire({
            title: 'Debes seleccionar un fichero',
            buttons: [{label: 'Ok'}]
        })
    }
  }

  return (<>
      <CardHeader title={"Subir Fichero"} action={<>
            <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
            onClick={()=>uploadFiles()}>
                Subir
            </Button>
            <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
        </>}/>
        <Divider style={{ margin: '15px'}} />
        <CardContent>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                <Grid item md={12}>
                    <FormControl fullWidth>
                      <Select
                        value={cert}
                        label="Certificado"
                        onChange={handleChange}
                      >
                          <MenuItem value={0}>Selecciona un certificado</MenuItem>
                          {certificados.map((e,i)=>{
                              return <MenuItem key={i} value={e.id}>{e.nombre}</MenuItem>
                          })}
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FileUpload key={fileKey} title="" header="[Arrastra el documento que quieras subir]" leftLabel="o" rightLabel="para seleccionarlo"
                        allowedExtensions={['vnd.openxmlformats-officedocument.spreadsheetml.sheet']} maxUploadFiles={1}
                        buttonLabel="Pulsa aquí" buttonRemoveLabel="Borrar" onFilesChange={handleFilesChange} onContextReady={(_context) => {}}/>
                </Grid>
            </Grid>
        </CardContent>
      </>
  );
}
