import React from 'react';
// material
import {Button, Card, CardHeader, CardContent, Divider, Modal} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
import CloseIcon from "@mui/icons-material/Close";
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import AgruForm from './form';
import {buildBody, isEmpty, workWithLocalStorage, getUniqueKey, compruebaForm} from '../../auxiliares/funciones';


// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [certificados, setCertificados] = React.useState([]);
  const [agrupacion, setAgrupacion] = React.useState({});
  const [cargado, setCargado] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());

    const campos = ['id','certificado','nombre','contador','activo'];
    const cabecera = ['ID','Certificado','Nombre','Contador','Estado'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
      if(!cargado){
        const body = {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}agrupaciones`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data.agrus);
              setCertificados(result.data.certs);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);            
            setCargado(true);
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true); 
      // setCargado(false);
      setCargado(false)
    }

    const validaBorrar = (obj) => obj.activo === 'Activo';
    const validaActivar = (obj) => obj.activo !== 'Activo';

    const Activar = (obj) =>{
          // eslint-disable-next-line react/prop-types
          props.showLoader(true); 
          const body = {
            tabla: "agrupaciones",
            activo: 1,
            id: obj.id
          }
          const bodyJson = JSON.stringify(buildBody(usuario.token,body))
          fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
              let msg ='Empresa activada!';
              if (result.error === 0) {   
                setLista([]);        
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
          })
    }

    const Borrar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
          tabla: "agrupaciones",
          activo: 0,
          id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Empresa desactivada!';
              if (result.error === 0) {    
                setLista([]);               
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const theme = useTheme();
    const acciones = [
      {
        color:'error',
        nombre:'Borrar',
        icono:<i />,
        funcion: Borrar,
        estilo: {},
        condition: validaBorrar
      },
      {
        color:'warning',
        nombre:'Activar',
        icono:<i />,
        funcion: Activar,
        estilo: {},
        condition: validaActivar
      }
    ]

    const FormAgru = {idWeb: 0, idCertificado: "", nombre: "", anyo: 0};
    const cierraModal = () => setModalOpen(false);
    const abreModal = () => setModalOpen(true);
    const obligatorios = ['idWeb','idCertificado','nombre','anyo'];

    const AltaAgrupacion = () =>{
        setAgrupacion(FormAgru);
        abreModal();
    }

    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const comA = agrupacion;
        comA[nombre] = valor
        setAgrupacion(comA);
    }

    const GrabarAgrupacion = () =>{
        const valido = compruebaForm(FormAgru,agrupacion,obligatorios);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true);
            const body = agrupacion;
            body.idUsuarioAlta = usuario.id;
            const bodyJson = JSON.stringify(buildBody(usuario.token,body))
            fetch(`${CONFIG.api}agrupacion/alta`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Agrupacion grabada!';
                if (result.error === 0) {
                    cierraModal();
                    setCargado(false);
                }else{
                    props.showLoader(false);
                    msg = result.errorMessage;
                }
                Swal.fire({
                    title: msg,
                    buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

  return (    
    <Page title="Empresas" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                                <Button variant="contained" color="primary" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>AltaAgrupacion()}>
                                  Nueva Agrupación
                                </Button>
                              </>}
                  title="Agrupaciones"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
        <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Card sx={style}>
                    <CardHeader title="Nueva Agrupación"
                    action={<>
                        <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                        onClick={()=>GrabarAgrupacion()}>
                            Grabar
                        </Button>
                        <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
                    </>}/>
                    <Divider style={{ margin: '15px'}} />
                    <CardContent>
                        <AgruForm showLoader={props.showLoader} certificados={certificados} agrupacion={agrupacion} actualizaCampo={actualizaCampo}/>
                    </CardContent>
                </Card>
            </Modal>
    </Page>
  );
}
